<template>
    <div class="">

        <!-- PAGE 1 intro start -->
        <div v-if="!uiUnlockAll" :class="pageVisibility(uiPages[0])">
            <introComponent />
        </div>
        <!-- PAGE 1 intro end -->

        <!-- PAGE 3 start -->
        <div :class="pageVisibility(uiPages[2])">
            <page3Component />
        </div>
        <!-- PAGE 3 end -->

        <div class="section4-contentpage chapter2-contentpage">
            <div class="section10">
                <div class="s3tableholder s7Div">
                    <div class="tabledim"></div>

                    <!-- PAGE 1 start -->
                    <div :class="pageVisibility(uiPages[0])" >
                        <page1Component 
                            v-on:sectionSubmit="page1UserSubmit" 
                            :roles="modelRoles" 
                            :regions="modelRegions" 
                            :regionsAll="modelRegionsAll"
                            :countriesList="modelCountriesAll"
                            :managers="modelManagers" 
                            :planning="planning"
                            :initload="initload"
                        />
                    </div>
                    <!-- PAGE 1 end -->


                    <!-- PAGE 2 start -->
                    <div :class="pageVisibility(uiPages[1])">
                        <page2Component 
                            v-on:section2Submit="page2UserSubmit" 
                            :goals="page2_Goals" 
                            :planning="planning"
                        />
                    </div>
                    <!-- PAGE 2 end -->

                </div>

            </div>
        </div>


        <!-- Loading screen start -->
        <div :class="isLoadingScreen()">
            <loadingComponent :msg="getLoadingText" />
        </div>
        <!-- Loading screen end -->

    </div>
</template>

<script>
    import introComponent from '@/components/capabilityParts/intro.vue'
    import page1Component from '@/components/capabilityParts/page1.vue'
    import page2Component from '@/components/capabilityParts/page2.vue'
    import page3Component from '@/components/capabilityParts/page3.vue'
    import loadingComponent from '@/components/capabilityParts/loading.vue'

    import _successionControllerAPI from '@/mixins/_successionControllerAPI'
    import _successionControllerLocalAPI from '@/mixins/_successionControllerLocalAPI'

    export default {
        name: 'Capability',
        mixins: [_successionControllerAPI, _successionControllerLocalAPI],
        data() {
            return {
                accessToken: '',
                claims: [],
                modelRoles: [],
                modelManagers: [],
                modelRegionsAll: [],
                modelRegions: [],
                modelCountriesAll: [],
                page2_Goals: [],
                planning: {},
                assessment: {},
                initload: false,

                uiUnlockAll: false,
                uiPages: [
                    { fadeEnd: true, visible: true,},
                    { fadeEnd: false, visible: false,},
                    { fadeEnd: false, visible: false,}
                ],
                uiLoadingScreen: false,
                uiLoadingText: '',
            }
        },
        computed: {
            getLoadingText() {
                return this.uiLoadingText;
            },
        },
        methods: {
            loadingOverlayStart(text, isFullScreenLoading, delay) {
                let self = this;

                if(!isFullScreenLoading) {
                    self.$emit('navLoadingStart');
                } else {
                    self.uiLoadingScreen = true;
                    self.uiLoadingText = text
                }

                if(delay != null) {
                    setTimeout(function() {
                        self.loadingOverlayStop(isFullScreenLoading);
                    }, Number(delay));
                }
            },
            loadingOverlayStop(isFullScreenLoading) {
                let self = this;
                
                if(!isFullScreenLoading) {
                    self.$emit('navLoadingStop');
                } else {
                    self.uiLoadingScreen = false;
                    self.uiLoadingText = ''
                }
            },
            isLoadingScreen() {
                var self = this;
                let classes = self.uiLoadingScreen ? 'fadeIn anim-035' : 'd-none';

                return classes;
            },
            pageVisibility(uiPage) {
                let classes = 'anim-035 ';

                if(!this.uiUnlockAll) {
                    classes += uiPage.fadeEnd && uiPage.visible ? 'fadeIn ' : 'd-none ';
                }

                return classes;
            },
            getOrgGuidById(companyId) {
                let self = this,
                    regions = self.regionsModel;

                if (regions != null) {
                    let selectedRegion = regions.filter((region) => {
                        return region.id == companyId
                    });

                    if (selectedRegion.length > 0)
                        selectedRegion = selectedRegion[0];

                    return selectedRegion.guid;
                }
            },
            page1UserSubmit(userItems, skillItems, skillGoals, moveToNextPage) {
                let self = this;

                self.page2_Goals = skillGoals;

                self.getUser(self.accessToken, (user)=> {
                    let name = user.name;
                    let email = user.email;

                    let nullGuid = "00000000-0000-0000-0000-000000000000";

                    let submitUser = {
                        "name": name,
                        "email": email,
                        "occupationId": userItems.roleId,
                        "organisationId":  userItems.regionId,
                        "managersId":  userItems.managerId,
                        "countryId": userItems.countryId,
                        "countryISOId": userItems.countryISOId
                    }

                    if(submitUser.occupationId == '' || 
                        submitUser.organisationId == '' || 
                        submitUser.organisationId == nullGuid || 
                        submitUser.countryId == '' || 
                        submitUser.managersId == '') {
                        alert("Please make sure to select the Role, Region and the Manager.");
                        return;
                    }
                    
                    self.loadingOverlayStart('Saving, <br/> please wait.', moveToNextPage);

                    self.postNewUser(self.accessToken, submitUser).then((response) => {
                        //console.log('postNewUser', response.data);
                        self.userData.user = response.data;

                        self.assessmentSave(self.accessToken, response.data.userId).then((response2) => {
                            self.assessment = response2.data;
                            self.userData.assessment = response2.data;

                            self.assessmentItemSave(self.accessToken, response2.data.assessmentID, skillItems).then(() => {
                                self.loadingOverlayStop(moveToNextPage)
                                self.localSave();

                                if(moveToNextPage)
                                    self.navigatePageSlideTo(1);

                            }).catch((err) => {
                                console.log("ERR", err);
                                self.loadingOverlayStart('Failure to save the capabilities data.', true, 10000);
                            });

                        }).catch((err) => {
                            console.log("ERR", err);
                            self.loadingOverlayStart('Failure to save the assessment data.', true, 10000);
                        });

                    }).catch((err) => {
                        console.log("ERR", err);
                        self.loadingOverlayStart('Failure to save user data.', true, 10000);
                    });

                });

            },
            page2UserSubmit(data, moveToNextPage) {
                let self = this;

                let id = self.userData.assessment.assessmentID;
            
                console.log('page2UserSubmit ::>>>', data, data.length);

                if(data == null || data.length == 0) {
                    console.log('Saving goals empty. Stop.');
                    return;
                }
                
                self.loadingOverlayStart('Saving, <br/> please wait.', moveToNextPage);
                
                self.assessmentItemsGoalsSave(self.accessToken, id, data).then((response) => {
                    console.log('Saving goals - ', response.data);

                    self.loadingOverlayStop(moveToNextPage)

                    function emailSentScreen() {
                        self.loadingOverlayStop(true)
                        self.navigatePageSlideTo(2);
                    }

                    function emailFailedScreen(status) {
                        self.loadingOverlayStart('Failure to send the email. Status code ' + status + '.', true, 10000);
                    }

                    //SEND EMAILS
                    if(moveToNextPage) {
                        let requiredCalls = 0; 

                        self.loadingOverlayStart('Sending email, <br/> please wait', moveToNextPage);

                        self.emailSendUser(function(callbackData, success) {
                            if(success) {
                                if(callbackData.data.status == 200) {
                                    requiredCalls ++;
                                    if(requiredCalls == 2)
                                        emailSentScreen();
                                } else {
                                    emailFailedScreen(callbackData.data.status);
                                }
                            } else{
                                emailFailedScreen(callbackData.status);
                            }
                        });

                        self.emailSendManager(function(callbackData, success) {
                            if(success) {
                                if(callbackData.data.status == 200) {
                                    requiredCalls ++;
                                    if(requiredCalls == 2)
                                        emailSentScreen();
                                } else {
                                    emailFailedScreen(callbackData.data.status);
                                }
                            } else{
                                emailFailedScreen(callbackData.status);
                            }
                        });

                    }

                }).catch((err) => {
                    console.log("ERR", err);
                    self.loadingOverlayStart('Failed to save the goals data.', true, 10000);
                });
                
            },
            navigatePageSlideTo(navIndex) {
                let self = this;

                document.documentElement.scrollTop = 0;

                self.uiPages.forEach((value, index) => { 
                    value.fadeEnd = false;
                    value.visible = navIndex == index;
                });

                setTimeout(() => {
                    self.uiPages.forEach((value) => { 
                        value.fadeEnd = true;
                    });
                }, 350);

            }
        },
        async created() {
            let self = this;

            self.accessToken = this.$auth.getAccessToken();
            self.claims = await Object.entries(await this.$auth.getUser()).map(entry => ({ claim: entry[0], value: entry[1] }));

            self.loadingOverlayStart('Loading, <br/> please wait', true);

            self.getStaticContent(self.accessToken, function (roles, mngrs, regionsAll, countriesAll) {
                self.modelRoles = roles.data;
                self.modelManagers = mngrs.data;
                self.modelRegionsAll = regionsAll.data;
                self.modelCountriesAll = countriesAll.data;

                let mainRegions = self.modelRegionsAll.filter(function(region) { 
                    return region.parentID.toLowerCase() == self.orgId.toLowerCase() && region.isActive
                });
                self.modelRegions = Object.assign([], mainRegions);

                // LOAD STATIC DATA
                self.staticData.roles = roles.data;
                self.staticData.managers = mngrs.data;
                self.staticData.regionsAll = regionsAll.data;
                self.staticData.countryList = countriesAll.data;
                self.staticData.regions = Object.assign([], mainRegions);

                self.loadingOverlayStop(true);

                self.getUser(self.accessToken, (user) => {
                    let email = user.email;
                    
                    console.log('getting capability assessment for...' , email);
            
                    self.login(self.accessToken, email).then((response) => {
                        //console.log('login', response.data);

                        if(response.data != null) {
                            self.userData.user = response.data;
                            
                            if(response.data.userId != null) {
                                self.getUserPlanning(self.accessToken, response.data.userId).then((response2) => {
                                    //console.log('planning', response2.data);
        
                                    if(response2.data != null) {
                                        self.userData.planning = response2.data;
                                        
                                        self.planning = response2.data;

                                        setTimeout(function() {
                                            self.initload = true;
                                        }, 1000);
                                    }
                                });
        
                                self.assessmentSave(self.accessToken, response.data.userId).then((response2) => {
                                    self.userData.assessment = response2.data;
                                    
                                    self.assessment = response2.data;
                                });
                            }

                        }
                        
                    });
                });

            });
        },
        components: {
            introComponent, 
            page1Component, 
            page2Component, 
            page3Component,
            loadingComponent
        },
    }
</script>
