<template>
    <div class="status-overlay">
        <div class="status-overlay--bg">
            <div class="status-overlay--block">
                <div class="status-overlay--title" v-html="statusMsg"></div>
                <img src="@/assets/img/loading.gif" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg"],
    computed: {
        statusMsg() {
            let self = this;

            let setMsg = self.msg;

            return setMsg;
        }
    }
}
</script>

<style scoped lang="scss">
    .status-overlay {
        display: block !important;

        &--bg {
            position: fixed;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,0.9);
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            z-index: 100;
        }

        img {
            margin-top: 40px;
            width: 100px;
            ;
        }

        &--block {
            width: 380px;
        }

        &--title {
            font-size: 2rem;
            color: white;
        }
    }
</style>