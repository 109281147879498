
<template>
    <div>
        <div class="table">
            <div class="row mb-2">
                <div class="col col-10 subtitle2 text-dg bb-line1">
                    MY BRAND BUILDING CAPABILITY ASSESSMENT WORKSHEET 
                </div>
            </div>

            <div class="row step1">
                <div class="col col-3 highlight">
                    <div class="namebox">MY ROLE/TITLE: </div>
                </div>
                <div class="col col-4 highlight">
                    <select class="theme-bf textentry1" v-model="modelRoleValue" @change="updateSkillsbyRole($event, true)">
                        <option disabled></option>
                        <option v-for="option in modelRoles" :key="option.id" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
                <div class="col col-1">

                </div>
                <div class="col col-3">

                </div>
            </div>

            <div class="row">
                <div class="col col-3 highlight">
                    <div class="namebox">MY DIVISION:</div>
                </div>
                <div class="col col-4 highlight">
                    <select class="theme-bf textentry1" v-model="modelDivisionValue">
                        <option disabled></option>
                        <option v-for="option in modelDivisions" :key="option.id" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
                <div class="col col-1"></div>
                <div class="col col-3"></div>
            </div>

            <div class="row">
                <div class="col col-3 highlight">
                    <div class="namebox">MY REGION:</div>
                </div>
                <div class="col col-4 highlight">
                    <select class="theme-bf textentry1" v-model="modelRegionValue">
                        <option disabled></option>
                        <option v-for="option in modelRegions" :key="option.id" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
                <div class="col col-1"></div>
                <div class="col col-3"></div>
            </div>

            <div class="row">
                <div class="col col-3 highlight">
                    <div class="namebox">MY MARKET: </div>
                </div>
                <div class="col col-4 highlight">
                    <select class="theme-bf textentry1" v-model="modelCountryValue">
                        <option disabled></option>
                        <option v-for="option in modelMarkets" :key="option.id" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
                <div class="col col-1"></div>
                <div class="col col-3"></div>
            </div>

            <transition>
                <div class="row" v-if="modelCountryNA">
                    <div class="col col-3 highlight">
                        <div class="namebox">MY COUNTRY: </div>
                    </div>
                    <div class="col col-4 highlight">
                        <select class="theme-bf textentry1" v-model="modelCountryISOValue">
                            <option disabled></option>
                            <option v-for="option in countriesList" :key="option.countryID" :value="option.countryID">{{ option.countryName }}</option>
                        </select>
                    </div>
                    <div class="col col-1"></div>
                    <div class="col col-3"></div>
                </div>
            </transition>

            <div class="row">
                <div class="col col-3 highlight">
                    <div class="namebox">MY MANAGER: </div>
                </div>
                <div class="col col-4 highlight">
                    <select class="theme-bf textentry1" v-model="modelManagerValue">
                        <option disabled></option>
                        <option v-for="option in modelManagers" :key="option.id" :value="option.id">{{ option.name }}</option>
                    </select>
                </div>
                <div class="col col-1">

                </div>
                <div class="col col-3">

                </div>
            </div>

            <div class="row step5">
                <div class="col col-3 highlight">
                    <div class="namebox">ASSESSMENT DATE: </div>
                </div>
                <div class="col col-4 highlight">
                    <input type="text" v-model="modelDateValue" class="textentry1 dateEntry" />
                </div>
                <div class="col col-1">
                </div>
            </div>

        </div>
        <div class="mainTable frmTable step6" style="display:block">
            <div class="instruction2 step6">
                When you complete your own Capability Assessment Worksheet you will need to enter your current assessed proficiency level, and supporting information, for all 9 Capabilities. However, for this walkthrough, we're just going to focus on the 'Media' capability.
                <div class="sIcon sIcon6"></div>
                <div class="prevTextButton">BACK</div>
                <div class="closeTextButton"></div>
                <div class="nextTextButton">NEXT</div>
            </div>
            
            <div class="row">
                <div class="col col-3 col-lg-2 highlight">
                    <div class="greycolheader">9 Capability <br />Areas</div>
                </div>
                <div class="col col-1 col-lg-1 highlight">
                    <div class="greycolheader">My Target <br />Level</div>
                </div>
                <div class="col col-1 col-lg-1 highlight">
                    <div class="greycolheader">My <br />Assessment<br />*</div>
                </div>
                <div class="col col-5 col-lg-5 highlight">
                    <div class="greycolheader">Supporting Info<br />(Max 2-5 Brief Points)</div>
                </div>
                <div class="col col-1 col-lg-1 highlight">
                    <div class="greycolheader">X = My <br />Areas to <br />Work On</div>
                </div>
            </div>

            <div class="skillsCon">

                <div class="row tInsight skillsrow" v-for="(n, index) in modelSkillsRow" :key="n.id">
                    <div class="tIcon" v-if="n.image" :style="{ backgroundImage: 'url('+ n.image +')' }"></div>
                    <div class="col col-3 col-lg-2">
                        <div class="tCol tCol1">{{n.name}}</div>
                    </div>
                    <div class="col col-1 col-lg-1 highlight">
                        <div class="tCol tCol2">
                            <div class="hWhite">
                                <input class="nInput digitInput" v-model="n.targetLevel" readonly maxlength="1" type="number" />
                            </div>
                        </div>
                    </div>
                    <div class="col col-1 col-lg-1 highlight">
                        <div class="tCol tCol3">
                            <div class="hWhite2">
                                <input class="nInput digitInput yourInput" v-model="n.yourInput" max="4" min="0" maxlength="1" type="number" onkeydown="return false" />
                            </div>
                        </div>
                    </div>
                    <div class="col col-5 col-lg-5 highlight">
                        <div class="tCol tCol4"><textarea class="nInput briefInput" v-on:change="changesDone" v-on:blur="sectionSaveOnBlur" v-model="n.brief" maxlength="500" /></div>
                    </div>
                    <div class="col col-1 col-lg-1 highlight colx">
                        <div class="tCol tCol5">
                            <div class="nInput xcross" @click="skillRowTick(index)">{{n.isActive ? "X" : ""}}</div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="skillsCon mb-5">

            </div>
            <div class="row">
                <div class="col col-4">
                    <a href="https://b-f.okta.com/app/brownformancorporation_cornerstone_1/exkinxehx1w5SCoOT0x7/sso/saml?RelayState=%252fDeepLink%252fProcessRedirect.aspx%253fmodule%253dloRegisterAndLaunch%2526lo%253d1d61350b-1791-4f11-b477-36611769ef5e" target="_blank">

                        <div class="row step6">

                            <div class="col col-12">
                                <div class="bannerlabel"><div class="cpicon"></div>CAPABILITY DICTIONARY</div>
                            </div>


                        </div>
                    </a>

                </div>
                <div class="">
                </div>

                <div class="offset-md-5">
                    <input @click="sectionSubmit(true)" type="button" value="Submit" class="btn btn-lg formSubmit">
                </div>
            </div>

        </div>

    </div>
</template>

<style lang="scss" scoped>
    .row {
        display: flex !important;
    }

    @media screen and (max-width: $breakpoint-tablet-portrait) {
        .table, .mainTable {
            margin-left: 42px;
        }
    }

    .text-dg {
        color: $darkgrey !important;
    }

    .bb-line1 { 
        border-bottom:1px solid #545151;
    }

    .v-enter-active,
    .v-leave-active {
        transition: all 0.5s ease-in-out;
        height: auto;

    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
        height: 0px;
        overflow: hidden;
    }

</style>

<script>
    // import _countriesList from '@/assets/scripts/countries.js'
    import _successionControllerAPI from '@/mixins/_successionControllerAPI'

    export default {
        props: ["roles", "regions", "regionsAll", "managers", "planning", "countriesList", "initload"],
        mixins: [_successionControllerAPI],
        data() {
            return {
                savingTimeout: null,
                changesDetected: false,
                userCurrentRole: '',
                modelPlanning: this.planning,
                modelRoleValue: '',
                modelDivisionValue: '',
                modelRegionValue: '',
                modelCountryValue: '',
                modelCountryISOValue: '00000000-0000-0000-0000-000000000000',
                modelManagerValue: '',
                modelDateValue: this.currentDate(null),
                modelSkills: { },
                modelSkillsRow: [
                    {
                        id: '',
                        name: '',
                        image: '',
                        maxLevel: 4,
                        targetLevel: 0,
                        description: '',
                        brief: '',
                        yourInput: 0,
                        isActive: false
                    }
                ],
            }
        },
        computed: {
            modelRoles() {
                return this.roles;
            },
            modelManagers() {
                let managers = this.managers.map((manager) => {
                    let newName = manager.name.replace("�", "a").replace("�", "e");
                    return {
                        id: manager.id,
                        name: newName + " (" + manager.email + ")",
                        email: manager.email
                    }
                });

                return managers;
            },
            modelDivisions() {
                return this.regionsAll.filter(function(region) {
                    return region.level == 1
                });
            },
            modelRegions() {
                let self = this;

                let returnDefaultVal = [ { "id": '', "name" : '', } ];

                if(self.modelDivisionValue == '')
                    return returnDefaultVal

                let _regions = this.regionsAll.filter(function(region) {
                    return region.parentID.toLowerCase() == self.modelDivisionValue.toLowerCase()
                });

                if(_regions.length > 0) {
                    return _regions
                } else {
                    let _notSelected =  [ { "id": '00000000-0000-0000-0000-000000000000', "name" : '"N/A', } ];

                    return _notSelected;
                }
            },
            modelMarkets() {
                let self = this;

                let returnDefaultVal = [ { "id": '', "name" : '', } ];
                
                if(self.modelRegionValue == '')
                    return returnDefaultVal;

                let _markets = this.regionsAll.filter(function(region) {
                    return region.parentID.toLowerCase() == self.modelRegionValue.toLowerCase()
                });

                if(_markets.length > 0) {
                    return _markets
                } else {
                    let _notSelected =  [ { "id": '00000000-0000-0000-0000-000000000000', "name" : 'N/A', } ];
                    
                    return _notSelected;
                }
            },
            modelCountryNA() {
                return this.modelCountryValue == "00000000-0000-0000-0000-000000000000"
            }
        },
        watch: {
            modelDivisionValue: function()  {
                let self = this;

                if(self.initload) {
                    self.modelRegionValue = '';
                    self.modelCountryValue = '';
                    self.modelCountryISOValue = '00000000-0000-0000-0000-000000000000';
                }
            },
            modelRegionValue: function()  {
                let self = this;

                if(self.initload) {
                    self.modelCountryValue = ''
                    self.modelCountryISOValue = '00000000-0000-0000-0000-000000000000';
                }
            },
            modelCountryValue: function()  {
                let self = this;

                if(self.initload) {
                    self.modelCountryISOValue = '00000000-0000-0000-0000-000000000000';
                }
            },
            planning: function(newVal) { // watch it    
                // console.log("Page 1 - planning loaded: newVal", newVal, "oldVal:", oldVal);

                let self = this;

                self.modelPlanning = newVal;

                self.fillUserFieldsWithData();
                
                self.fillCapabilitiesWithData();
            },
        },
        methods: {
            isGuidSelected(guid) {
                return guid != null && guid != '' && guid != '00000000-0000-0000-0000-000000000000'
            },
            // invalidModelCountryValue() {
            //     let self = this;
            //     let found = this.modelMarkets.filter(function(region) {
            //         return region.id.toLowerCase() == self.modelCountryValue.toLowerCase()
            //     });

            //     console.log('found :>> ', found);
            //     console.log('found.length :>> ', found.length);
    
            //     return found.length == 0 && this.modelMarkets.length > 0;
            // },
            changesDone() {
                this.changesDetected = true;
            },
            getDivisionParent(regionId) {
                let region = this.regionsAll.filter(function(region) {
                    return region.id.toLowerCase() == regionId.toLowerCase()
                });

                if(region.length > 0) {
                    return region[0].parentID
                }
            },
            fillUserFieldsWithData() {
                let self = this;

                let newVal = self.modelPlanning;

                if(newVal.user != null) {
                    let roleId = newVal.user.occupationId,
                        regionId = newVal.user.organisationId,
                        managerId = newVal.user.managersId,
                        countryId = newVal.user.countryId,
                        countryISOId = newVal.user.countryISOId;

                    self.userCurrentRole = roleId;
                    self.modelRoleValue = roleId;
                    self.modelManagerValue = managerId;

                    self.updateSkillsbyRole(null, false);

                    self.modelDivisionValue = self.getDivisionParent(regionId);
                    self.modelRegionValue = regionId;
                    self.modelCountryValue = countryId;
                    self.modelCountryISOValue = countryISOId;
                }

                if(newVal.assessment != null) {
                    let planningUpdatedDate = (newVal.assessment.isCompleted == null || newVal.assessment.isCompleted == '') ? null : newVal.assessment.isCompleted;
                    let planningCreatedDate = (newVal.assessment.created == null || newVal.assessment.created == '') ? null : newVal.assessment.created;

                    let showDate = planningUpdatedDate == null ? planningCreatedDate : planningUpdatedDate;
                    self.modelDateValue = self.currentDate(showDate);
                } else {
                    self.modelDateValue = self.currentDate(null);
                }

            },
            fillCapabilitiesWithData() {
                let self = this;

                let newVal = self.modelPlanning;

                if(newVal.assessmentItems != null) {
                    // console.log('newVal.assessmentItems', newVal.assessmentItems);   
                    let updatedSkills = self.modelSkillsRow;

                    newVal.assessmentItems.forEach((value) => {
                        let skillID = value.skillID;
                        let text = value.evidenceText;
                        let scale = value.selectedScale;
                        let isActive = false;

                        if(value.assessmentGoals != null) {
                            if(value.assessmentGoals.length > 0)
                                isActive = true;
                        }

                        let skill = updatedSkills.filter((skill) => {
                            return skill.id == skillID
                        });

                        
                        if(skill.length > 0) {
                            skill = skill[0];
                            skill.brief = text;
                            skill.yourInput = scale;
                            skill.isActive = isActive;
                        }
                    });

                    // console.log('updatedSkills value', updatedSkills);   

                    self.modelSkillsRow = updatedSkills;
                }

            },
            currentDate(dateToFormat) {
                var dateNow = dateToFormat == null ? new Date() : new Date(dateToFormat);
                var twoDigitMonth = ((dateNow.getMonth().length + 1) === 1) ? (dateNow.getMonth() + 1) : '' + (dateNow.getMonth() + 1);
                var currentDate = dateNow.getDate() + "/" + twoDigitMonth + "/" + dateNow.getFullYear();
                return currentDate;
            },
            skillsBriefFilledCount() {
                let self = this;

                if(self.modelRoleValue != '') {
                    return self.modelSkillsRow.filter(item => (item.brief != '' && item.brief != null));
                }
            },
            skillsSelected() {
                let self = this;

                if(self.modelRoleValue != '') {
                    return self.modelSkillsRow.filter(item => item.isActive);
                }
            },
            skillsSelectedCount() {
                let self = this;

                if(self.modelRoleValue != '') {
                    return self.modelSkillsRow.filter(item => item.isActive).length;
                }
            },
            updateSkillsbyRole(e, checkReplace) {
                let self = this,
                roleid = self.modelRoleValue;

                if(checkReplace) {
                    // first time selecting role
                    if(self.userCurrentRole != '') {
                        if (confirm("Are you sure that you want to change the role? This will clear the capabilities supporting info and update the target levels.")) {
                            console.log('role changed');
                        } else {
                            self.modelRoleValue = self.userCurrentRole;
                            return;
                        }
                    }
                }

                if(roleid == null)
                    return;

                if(self.roles != null) {
                    if(self.roles.length > 0) {

                        let getRole = self.roles.filter((role) => role.id == roleid);

                        self.userCurrentRole = self.modelRoleValue;

                        if(getRole.length > 0) {
                            getRole = getRole[0];
                            let skills = getRole.skills;

                            if(skills.length > 0) {
                                self.modelSkillsRow = skills.map((skill) => {
                                    return {
                                        id: skill.id,
                                        name: skill.name,
                                        image: require("/src/assets/img/section3/icons/skillicons/"+ skill.id +".png"),
                                        maxLevel: skill.maxLevel,
                                        targetLevel: skill.targetLevel,
                                        description: skill.description,
                                        brief: skill.brief == null ? '' : skill.brief,
                                        yourInput: skill.yourInput == null ? '0' : skill.yourInput,
                                        isActive: skill.isActive,
                                    }
                                });
                                

                                //role re-selected - re-fill fields
                                if(self.modelPlanning != null) {
                                    if(self.modelPlanning.user != null) {
                                        if(self.modelPlanning.user.occupationId != null) {
                                            if(self.modelPlanning.user.occupationId == getRole.id) {
                                                self.fillCapabilitiesWithData();
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            },
            skillRowTick(skillIndex) {
                let self = this;

                if(self.modelRoleValue != '') {
                    if(self.modelSkillsRow[skillIndex].isActive) {
                        if (confirm("Are you sure that you want to remove this goal from your plan?")) {
                            self.modelSkillsRow[skillIndex].isActive = false;
                        }
                    } else {
                        if(self.skillsSelectedCount() < 3) {
                            self.modelSkillsRow[skillIndex].isActive = true;
                        }
                    }
                }
            },
            sectionSaveOnBlur() {
                let self = this;

                if(self.changesDetected) {
                    
                    self.savingTimeout = setTimeout(function() {
                        self.sectionSubmit(false);
                        clearTimeout(self.savingTimeout);
                    }, 500);

                    self.changesDetected = false;
                }
            },
            sectionSubmit(moveToNextPage) {
                let self = this;

                let allCompleted = true;
                let nullGuid = "00000000-0000-0000-0000-000000000000";
                let roleSelected = self.modelRoleValue != '' && self.modelRoleValue != null;

                if(!self.isGuidSelected(self.modelRoleValue)) {
                    alert('Please make sure Role is selected.');
                    allCompleted = false;
                    return;
                }

                if(!self.isGuidSelected(self.modelDivisionValue)) {
                    alert('Please make sure Division is selected.' + self.modelDivisionValue);
                    allCompleted = false;
                    return;
                }
                
                if(!self.isGuidSelected(self.modelRegionValue)) {
                    alert('Please make sure Region is selected.');
                    allCompleted = false;
                    return;
                }

                if(!self.isGuidSelected(self.modelCountryValue) && !self.isGuidSelected(self.modelCountryISOValue)) {
                    alert('Please make sure Market or Country is selected.');
                    allCompleted = false;
                    return;
                }

                if(!self.isGuidSelected(self.modelManagerValue)) {
                    alert('Please make sure Manager is selected.');
                    allCompleted = false;
                    return;
                }
                
                if(!self.isGuidSelected(self.modelCountryISOValue)) {
                    self.modelCountryISOValue = nullGuid;
                }

                let userRecords = {
                    roleId: self.modelRoleValue,
                    regionId: self.modelRegionValue,
                    managerId: self.modelManagerValue,
                    countryId: self.modelCountryValue,
                    countryISOId: self.modelCountryISOValue
                }

                let skillRecords = self.modelSkillsRow.map(function(item) {
                    let brief = item.brief;

                    if(brief == '' || brief == null) {
                        console.log('!!! - empty brief :>>  set allCompleted to false');

                        allCompleted = false;
                    }

                    //TODO: ENCODING
                    let encodedText = btoa(escape(brief));
                    
                    return {
                        "skillID": item.id,
                        "selectedScale": Number(item.yourInput),
                        "evidenceText": encodedText
                    };
                });

                let skillSelectedRecords = self.skillsSelected();

                let skillsSelectedCount = self.skillsSelectedCount();

                if(roleSelected)
                    if(moveToNextPage) {

                        if(skillsSelectedCount == 0) {
                            alert('Please tick at least one skill.');
                        } else {
                            if(allCompleted) {
                                self.$emit('sectionSubmit', userRecords, skillRecords, skillSelectedRecords, moveToNextPage);
                            } else {
                                alert('Please make sure all fields are filled.');
                            }
                        }

                    } else {
                        self.$emit('sectionSubmit', userRecords, skillRecords, skillSelectedRecords, moveToNextPage);
                    }
                
            }
        },
    }
</script>
