
<template>
    <div>
        <div class="mainTable formSection2" style="display:block">
            <div class="row">
                <div class="col col-10 subtitle2" style="border-bottom:1px solid #545151;">
                    MAKE A DEVELOPMENT PLAN
                </div>
            </div>
            <div class="s10-2 pr-5 mr-5">
                <div class="row">
                    <div class="col col-10">
                        Now, make a plan!  For those areas that need improvement, identify 70-20-10 actions and any needed support:
                    </div>
                </div>
                <div class="row">
                    <div class="col col-3 highlight"></div>
                    <div class="col col-11 col-lg-6 highlight">
                        <div class="whitefield">
                            <div><h5 class="subtitle1">MY FOCUS:</h5></div>
                            <div><p>What Capability Areas or sub-areas do I want to develop?</p></div>
                        </div>
                    </div>
                </div>

                <div class="row" v-for="(n, index) in modelSkillsGoals" :key="n.id">
                    <div class="col col-11 col-lg-3 highlight"><div class="namebox mla">{{index + 1}}: </div></div>
                    <div class="col col-11 col-lg-6 highlight focusTxt">
                        <input type="text" class="textentry1 area1 " id="area1" :value="n.name" disabled />
                    </div>
                </div>
        
                <div class="row">
                    <div class="col col-11 col-lg-4 highlight">
                        <div class="whitefield">
                            <div><p class="subtitle1">My Activities:</p></div>
                            <div>Specifically, how will I develop in this area?</div>
                        </div>
                    </div>
                    <div class="col col-11 col-lg-4 highlight">
                        <div class="whitefield">
                            <div><p class="subtitle1">My Support:</p></div>
                            <div><p>What approvals, info, funds, and support will I need?</p></div>
                        </div>
                    </div>
                    <div class="col col-11 col-lg-4 highlight">
                        <div class="whitefield">
                            <div><p class="subtitle1">My Success:</p></div>
                            <div><p>How will I measure success, by when?</p></div>
                        </div>
                    </div>
                </div>

                <div class="row print-pagebreak">
                    <div class="col col-10 subtitle2 pt-3" style="border-bottom:1px solid #545151;">
                        My Development Plan Worksheet
                    </div>
                </div>

                <div class="goalsCon">
                    <div class="row inputRow mb-5" v-for="(skill) in modelSkillsGoals" :key="skill.id">
                        <div class="tIcon tGIcon" :title="skill.name" v-if="skill.image" :style="{ backgroundImage: 'url('+ skill.image +')' }"></div>

                        <div class="col col-11 col-lg-4 goalSec" v-for="(goalTypeId) in modelGoalTypes" :key="goalTypeId">
                            <div class="tCol tCol4 icol1 acInput">
                                <textarea v-model="goalsBriefs[skill.id + ':' + goalTypeId]" maxlength="500" v-on:change="changesDone" v-on:blur="sectionSaveOnBlur" class="nInput"/>
                            </div>
                        </div> 

                    </div>
                </div>

                <div class="row mt-5 pt-5">
                    <div class="col col-6">
                        <a href="https://b-f.okta.com/login/login.htm?fromURI=%2Fapp%2Fbrownformancorporation_cornerstone_1%2Fexkinxehx1w5SCoOT0x7%2Fsso%2Fsaml%3FRelayState%3D%25252fDeepLink%25252fProcessRedirect.aspx%25253fmodule%25253dlodetails%252526lo%25253dd41a38d4-ff6d-4fe7-aad8-afe4cff5812d" target="_blank">
                            <div class="row step6">
                                <div class="col col-12">
                                    <div class="bannerlabel"><div class="cpicon"></div>DEVELOPMENT BANK OF IDEAS</div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="col col-6 text-right">
                        <input type="button" @click="section2Submit" value="Save & Submit" class="btn btn-lg formSubmit ml-5" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped>
    .row {
        display: flex !important;
    }
</style>

<script>
    import _successionControllerAPI from '@/mixins/_successionControllerAPI'

    export default {
        props: ["goals", "planning"],
        mixins: [_successionControllerAPI],
        data() {
            return {
                changesDetected: false,
                goalsSubmitted: {},
                goalsPlanningText: {},
                goalsBriefs: {},
                modelGoalTypes: [
                    //activities typeid
                    'abf3bb94-de22-4a6f-a264-a65eae34510f',
                    //support typeid
                    '78445d19-16e1-4275-b08b-272a079fa439',
                    //success typeid
                    'e07e149e-cd16-4f6f-b033-065d4ebaafef'
                ]
            }
        },
        computed: {
            modelSkillsGoals() {
                return this.goals;
            },
        },
        watch: {
            goals: function(newVal) { // watch it    
                // console.log("Page 2 - goals loaded: newVal", newVal, "oldVal:", oldVal);

                this.goalsSubmitted = newVal;
                this.updateGoalsTextFromPlanning();
            },
            planning: function(newVal) { // watch it    
                let self = this;
                // console.log("Page 2 - planning loaded: newVal", newVal, "oldVal:", oldVal);

                self.goalsPlanningText = newVal;
            }
        },
        methods: {
            changesDone() {
                this.changesDetected = true;
            },
            updateGoalsTextDefaultValues() {
                let self = this;

                let selSkills = self.modelSkillsGoals;
                let goalTypes = self.modelGoalTypes;

                selSkills.forEach((skill) => {
                    goalTypes.forEach((goalTypeId) => {
                        self.goalsBriefs[skill.id + ':' + goalTypeId] = '';
                    });
                });
            },
            updateGoalsTextFromPlanning() {
                let self = this;

                self.updateGoalsTextDefaultValues();

                let newGoalsBriefs = {}; 

                if(self.goalsPlanningText != null) {
                    if(self.goalsPlanningText.assessmentItems != null ) {
                        if(self.goalsPlanningText.assessmentItems.length > 0) {
                            self.goalsPlanningText.assessmentItems.forEach((skill) => {
                                if(skill.assessmentGoals != null) {
                                    if(skill.assessmentGoals.length > 0) {
                                        skill.assessmentGoals.forEach((goal) => {
                                            newGoalsBriefs[skill.skillID + ":" + goal.assessmentGoalTypeID] = goal.goalText == null ? '': goal.goalText;
                                        });
                                    }
                                }
                            });

                        }
                    }
                }

                self.goalsBriefs = Object.assign(self.goalsBriefs, newGoalsBriefs);

                self.changesDetected = true;
                self.sectionSaveOnBlur();
            },
            sectionSaveOnBlur() {
                let self = this;

                if(self.changesDetected) {
                    setTimeout(() => self.section2Submit(false), 500);
                    self.changesDetected = false;
                }
            },
            section2Submit(moveToNextPage) {
                let self = this;

                if(self.goalsSubmitted != null && self.modelGoalTypes != null) {
                    let allFormsCompleted = true;

                    let mapItems = Object.keys(self.goalsBriefs).map(function(itemId) {
                        let skillId = itemId.split(':')[0].trim();

                        let typeId = itemId.split(':')[1].trim();

                        let value = self.goalsBriefs[itemId];

                        if(value == null || value == '')
                            allFormsCompleted = false;

                        value = (value == null ? '' : value);

                        //TODO: ENCODING
                        let encodedText = btoa(escape(value));
                        
                        return {
                            "skillID": skillId,
                            "assessmentGoalTypeID": typeId,
                            "goalText": encodedText
                        };
                    });

                    let mapItemsSubmitted = mapItems.filter((x)=> {
                        let isSubmitted = self.goalsSubmitted.filter((y)=> y.id == x.skillID);
                        return isSubmitted.length > 0;
                    });

                    if(moveToNextPage) {
                        if(allFormsCompleted) {
                            self.$emit('section2Submit', mapItemsSubmitted, moveToNextPage);
                        } else {
                            alert('Make sure all fields are filled.');
                        }
                    } else {
                        self.$emit('section2Submit', mapItemsSubmitted, moveToNextPage);
                    }
                
                }

            },
        },
    }
</script>
