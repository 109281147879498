<template>
    <div>
        <div class="section4-contentpage chapter2-contentpage pb-5 mb-5">
            <div class="section10">
                <div class="s3tableholder s7Div">
                        
                        <div class="table">
                            <div class="row mb-2">
                                <div class="col col-10 subtitle2 text-dg">
                                    My Staff
                                </div>
                            </div>
                        </div>

                        <div v-if="userHasStaff" class="mainTable frmTable step5" style="display:block">
                            <div class="row">
                                <div class="col col-lg-4">
                                    <div class="greycolheader">Name / Email</div>
                                </div>
                                <div class="col col-lg-2">
                                    <div class="greycolheader">Role</div>
                                </div>
                                <div class="col col-lg-2">
                                    <div class="greycolheader">Region</div>
                                </div>
                                <div class="col col-lg-1">
                                    <div class="greycolheader">Actions</div>
                                </div>
                            </div>

                            <div class="row align-items-stretch row-items" v-for="n in staff" :key="n.id">
                                <div class="col col-lg-4">
                                    <div class="py-3 px-3 bg-col1">
                                        {{n.name}} <br/>
                                        <a :href="'mailto:'+ n.email">{{n.email}}</a>
                                    </div>
                                </div>
                                <div class="col col-lg-2">
                                    <div class="py-3 px-3 bg-col2">{{n.role}}</div>
                                </div>
                                <div class="col col-lg-2">
                                    <div class="py-3 px-3 bg-col3">{{n.region}}</div>
                                </div>
                                <div class="col col-lg-1">
                                    <div class="py-3 px-3 bg-col4" @click="openUserCapability(n.email)">View</div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="table" v-else>
                            <div class="row mb-2">
                                <div class="col col-10 text-dg">
                                    No staff assigned yet.
                                </div>
                            </div>
                        </div>

                </div>
            </div> 
        </div>

        <!-- Loading screen start -->
        <div :class="isLoadingScreen()">
            <loadingComponent :msg="getLoadingText" />
        </div>
        <!-- Loading screen end -->
    </div>
</template>

<style lang="scss" scoped>
    .text-dg {
        color: $darkgrey !important;
    }

    .row {
        display: flex !important;
    }

    .row-items { 
        a {
            color: white;
        }

        .bg-col1,
        .bg-col2,
        .bg-col3,
        .bg-col4 {
            height:100%;
        }
        
        .bg-col1 {
            background-color:#796659;
            color:white;
        }

        .bg-col2 {
            background-color:#A1948B;
            color:white;
        }
        .bg-col3 {
            background-color: #BCB3AC;
            color:white;
        }

        .bg-col4 {
            background-color: white;
            cursor:pointer;
        }
    }
</style>


<script>
    import loadingComponent from '@/components/capabilityParts/loading.vue'

    import _successionControllerAPI from '@/mixins/_successionControllerAPI'
    import _successionControllerLocalAPI from '@/mixins/_successionControllerLocalAPI'

    export default {
        mixins: [_successionControllerAPI, _successionControllerLocalAPI],
        data() {
            return {
                staff: [],
                hasStaff: false,
                    
                uiLoadingScreen: false,
                uiLoadingText: '',
            };

        },
        computed: {
            userHasStaff() {
                return this.hasStaff;
            },
            getLoadingText() {
                return this.uiLoadingText;
            },
        },
        methods: {
            isLoadingScreen() {
                var self = this;
                let classes = self.uiLoadingScreen ? 'fadeIn anim-035' : 'd-none';

                return classes;
            },
            openUserCapability(email) {
                var windowFeatures = "menubar=no,location=no,resizable=no,scrollbars=no,status=no";

                window.open("/?user_email=" + email, "_blank", windowFeatures);
            }
        },
        async created() {
            let self = this;

            self.accessToken = this.$auth.getAccessToken();
            self.claims = await Object.entries(await this.$auth.getUser()).map(entry => ({ claim: entry[0], value: entry[1] }))

            let email = self.claims[3].value;

            if(self.overwriteStaffEmail != '') {
                console.warn('overwrite asociated staff email with..', self.overwriteStaffEmail);
                email = self.overwriteStaffEmail;
            }

            self.uiLoadingScreen = true;
            self.uiLoadingText = 'Loading, <br/> please wait';
            
            self.getStaticContent(self.accessToken, function (roles, mngrs, regionsAll, countriesAll) {
                // LOAD STATIC DATA
                self.staticData.roles = roles.data;
                self.staticData.managers = mngrs.data;
                self.staticData.regionsAll = regionsAll.data;
                self.staticData.countryList = countriesAll.data;

                self.usersByManager(self.accessToken, email).then((response) => {
                    // console.log(response);
                    // console.log(response.data);

                    let err = self.isNullEmpty(response.data);

                    if(err.s) {
                        self.uiLoadingScreen = false;
                        console.log(err.m);
                        return;
                    }

                    console.log('userStaff response.data', response.data)

                    let userStaff = response.data.map((user) => {
                        let roleName = self.getRoleName(user.occupationId),
                            regionName = self.getRegionName(user.organisationId);
                        
                        return {
                            id: user.userId,
                            name: user.name,
                            role: roleName,
                            region: regionName,
                            email: user.email
                        }
                    });

                    console.log('userStaff 2 response.data', userStaff)


                    // console.log('userStaff', userStaff);

                    self.staff = userStaff;

                    self.uiLoadingScreen = false;
                    self.hasStaff = true;

                });
                
            });

        },
        components: {
            loadingComponent
        },

    };
</script>