
<template>
    <div>
        <div id="chapter" class="chapter-main section4-main formSectionhead1" :style="{ backgroundImage: 'url('+ headerImage +')' }">
            <div class="chapter-part-text"></div>
            <!-- <div><h3 class="chapter-caption-text">Section 7</h3></div> -->
            <!-- <br /> -->
            <div><h1 class="chapter-title-text text-uppercase">Complete your <br />Draft Assessment <br />and Development Plan</h1></div>
        </div>
    </div>
</template>

<style lang="scss">
    #chapter {
        display: block;
    }
</style>

<script>
    export default {
        data() {
            let headerImage = require("/src/assets/img/chapter7/chapter7begin.jpg");
            
            return {
                headerImage: headerImage,
            }
        },
        mounted() {

        }
    }
</script>
