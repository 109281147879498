
<template>
    <div>
        <div id="chapter" class="chapter-main section4-main formFinish" :style="{ backgroundImage: 'url('+ headerImage +')' }">
            <div class="chapter-part-text"></div>
            <div>
                <h3 class="chapter-caption-text">
                    Congratulations. <br/>
                </h3>
                <h3 class="chapter-caption-text">
                    Your <b>Draft Assessment and Draft Development Plan</b><br/> have now been submitted to your manager.
                </h3>
            </div>
        </div>

        <div class="section4-contentpage chapter2-contentpage">
            <div class="s3tableholder s7Div">
                <div class="py-5 formFinish">
                    <div class="col-6 offset-1">
                        <div data-i18n_ap="three-steps-2-1">
                            <p class="subtitle1 mb-2">
                                A copy will also be sent to your inbox. 
                            </p>
                        </div>
                        <div data-i18n_ap="three-steps-2-2">
                            <p class="subtitle1">
                                You are now ready to book your 1-hour meeting to discuss these drafts with your manager. 
                                <br/>
                                Please allow at least 1 week from now so your manager has time to review your materials
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>


<style scoped lang="scss">
    .s3tableholder {
        background-color: white;
    }
</style>

<script>
    export default {
        data() {
            let headerImage = require("/src/assets/img/chapter7/chapter7finish.jpg");

            return {
                headerImage: headerImage,
            }
        }
    }
</script>