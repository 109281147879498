const axios = require('axios');


/* eslint-disable */
export default {
    name: 'capabilityAPI',
    data: function() {
        let setEndPoint = process.env.ENDPOINT;
        // let setEndPoint = "https://localhost:44357/api/sp"

        return {
            endpoint: setEndPoint,
            orgId: "6024647B-0F6D-4A05-AB97-497F6189341C"
        };
    },
    mounted: function() {
        console.log("API mounted.");
    },
    methods: {
        /*
            START GET METHODS
        */
        getSkillsbyOccupations(accessToken, occGuid, orgGuid) {
            if (occGuid == null || orgGuid == null)
                return;

            let self = this,
                settings = {
                    "url": self.endpoint + "skillsByOccIdCompId?occupationGuid=" + occGuid + "&companyId=" + orgGuid,
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
        getSkills(accessToken) {
            if(accessToken == null)
                return;

            let self = this,
                settings = {
                    "url": self.endpoint + "skills",
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
        getOccupations(accessToken, orgGuid) {
            if (orgGuid == null && accessToken == null)
                return;

            let self = this,
                settings = {
                    "url": self.endpoint + 'occupations?companyId=' + orgGuid,
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
        getManagers(accessToken) {
            if(accessToken == null)
                return;

            let self = this,
                settings = {
                    "url": self.endpoint + 'managersList',
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
        usersByManager(accessToken, email) {
            let self = this,
                settings = {
                    "url": self.endpoint + 'usersByManager?managerEmail=' + email,
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
        getCountriesAllIso(accessToken) {
            let self = this,
                settings = {
                    "url": self.endpoint + "countriesIso",
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
        getCompanyAll(accessToken) {
            let self = this,
                settings = {
                    "url": self.endpoint + "companiesAll",
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
        getCompanyAndRegion(accessToken, companyId) {
            if (companyId == null)
                return;

            let self = this,
                settings = {
                    "url": self.endpoint + "companies?companyId=" + companyId,
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },
            
        getUserPlanning(accessToken, userid) {
            if(userid == null)
                return;

            let self = this,
                settings = {
                    "url": self.endpoint + 'userPlanning?userId=' + userid,
                    "method": "GET",
                    "timeout": 0,
                    "headers": {
                        "Authorization": `Bearer ${accessToken}`
                    }
                };

            return axios(settings);
        },

        //STATIC CONTENT - ROLES, MANAGERS, REGIONS
        getStaticContent(accessToken, callback) {
            let self = this;

            let roles = this.getOccupations(accessToken, self.orgId);
            let mngrs = this.getManagers(accessToken);
            // let regions = this.getCompanyAndRegion(accessToken, self.orgId);
            let regionsAll = this.getCompanyAll(accessToken);
            let countriesAll = this.getCountriesAllIso(accessToken);

            axios.all([roles, mngrs, regionsAll, countriesAll]).then(axios.spread((...responses) => {
                const roles = responses[0];
                const mngrs = responses[1];
                const regionsAll = responses[2];
                const countriesAll = responses[3];

                if (typeof callback == "function") {
                    callback(roles, mngrs, regionsAll, countriesAll);
                }

            })).catch(errors => {
                console.error(errors);
            })
        },
        /*
            END GET METHODS
        */

        /*
            START POSTS METHODS
        */      
        loginAsManager(accessToken, user_email, manager_email) {
            if (user_email == '' || manager_email == '') {
                console.error("loginAsManager wrong data format");
                return;
            }

            let self = this,
                settings = {
                "url": self.endpoint + "loginAsManager?user_email=" + user_email + "&manager_email=" + manager_email,
                "method": "POST",
                "timeout": 0,
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
            };

            return axios(settings);
        },  
        login(accessToken, email) {
            if (email == '') {
                console.error("login wrong data format");
                return;
            }

            let self = this,
                settings = {
                "url": self.endpoint + "login?email=" + email,
                "method": "POST",
                "timeout": 0,
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
            };

            return axios(settings);
        },
        postNewUser(accessToken, formReq) {
            if (!formReq.name && !formReq.email && !formReq.occupationId && !formReq.organisationId && !formReq.managersId) {
                console.error("postNewUser wrong data format");
                return { status: 404 };
            }

            let self = this,
                settings = {
                "url": self.endpoint + "newPlanningUser2",
                "method": "POST",
                "timeout": 0,
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                "data": JSON.stringify(formReq),
            };

            return axios(settings);
        },

        assessmentSave(accessToken, userId) {
            if (userId == null) {
                console.error("assessmentSave wrong data format", userId);
                return { status: 404 };
            }

            let self = this,
                settings = {
                "url": self.endpoint + "assessmentSave?userId=" + userId,
                "method": "POST",
                "timeout": 0,
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
            };

            return axios(settings);
        },

        assessmentItemSave(accessToken, assessmentId, formReq) {
            if (!isNaN(assessmentId)) {
                console.error("assessmentItemSave wrong data format");
                return { status: 404 };
            }

            if (formReq.length == 0) {
                console.error("assessmentItemSave wrong data format");
                return { status: 404 };
            }

            let self = this,
                settings = {
                "url": self.endpoint + "assessmentItemsSave?assessmentId=" + assessmentId,
                "method": "POST",
                "timeout": 0,
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                "data": JSON.stringify(formReq),
            };

            return axios(settings);
        },
        assessmentItemsGoalsSave(accessToken, assessmentId, goalReq) {
            if (!isNaN(assessmentId)) {
                console.error("assessmentItemsGoalsSave wrong data format");
                return { status: 404 };
            }

            if (goalReq.length == 0) {
                console.error("assessmentItemsGoalsSave wrong data format");
                return { status: 404 };
            }

            let self = this,
                settings = {
                "url": self.endpoint + "assessmentItemsGoalsSave?assessmentId=" + assessmentId,
                "method": "POST",
                "timeout": 0,
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                "data": JSON.stringify(goalReq),
            };

            return axios(settings);
        },
        summaryNotify(accessToken, userid, messageBody) {  
            let self = this,
                settings = {
                "url": self.endpoint + "summaryNotify?userid=" + userid,
                "method": "POST",
                "timeout": 0,
                "headers": {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                },
                "data": JSON.stringify(messageBody),
            };

            return axios(settings);
        },

        /*
            START Email METHODS
        */
        emailSummaryObject(callback) {
            let self = this;

            let err = self.isNullEmpty(self.claims);

            if(err.s) {
                console.error(err.m);
                return;
            }

            self.getUser(self.accessToken, (user) => {
                let name = user.name,
                    email = user.email;

                console.log('emailing summary of.. ', email);
    
                self.login(self.accessToken, email).then((response) => {
                    console.log("LOGIN", response.data);
    
                    if(response.data != null) {
                        let user = response.data;

                        console.log("user.userId", user.userId);

                        if(user.userId != null) {
    
                            self.getUserPlanning(self.accessToken, user.userId).then((response2) => {
                                console.log("getUserPlanning", response2.data);
    
                                if(response2.data != null) {
                                    let planning = response2.data;
    
                                    if(planning.user == null || planning.assessment == null || planning.assessmentItems == null) {
                                        console.error('planning case 1 error');
                                        return;
                                    }
    
                                    if(planning.user.userId == null || planning.assessment.assessmentID == null || planning.assessmentItems.length == 0) {
                                        console.error('planning case 2 error');
                                        return;
                                    }

                                    let region = self.getRegion(planning.user.organisationId);
                                    let division = self.getRegion(region.parentID);
                                    let market = self.getRegion(planning.user.countryId);
                                    let country = self.getCountryIso(planning.user.countryISOId);
                                    let notSelected = "Not selected.";

                                    let summary = {
                                        mname: self.getManagerName2(planning.user.managersId),
                                        name: name,
                                        email: email,
                                        role: self.getRoleName(planning.user.occupationId),
                                        division: division == null ? notSelected : division.name,
                                        region: region == null ? notSelected : region.name,
                                        market: market == null ? notSelected : market.name,
                                        country: country == null ? notSelected : country.countryName,
                                        manager: self.getManagerName(planning.user.managersId),
                                        updated: self.getDate(planning.assessment.created),
                                        skills: [],
                                        selectedSkills: [],
                                        goals: []
                                    }

                                    let roleSkills = self.getRoleSkills(planning.user.occupationId);
                                    let skills = planning.assessmentItems;
    
                                    for (let i = 0; i < skills.length; i++) {
                                        const skill = skills[i];
    
                                        let skillData = self.getSkill(roleSkills, skill.skillID);
    
                                        let summarySkill =  {
                                            name: skillData.name,
                                            target: skillData.targetLevel,
                                            current: skill.selectedScale,
                                            brief: skill.evidenceText
                                        };
                                        
                                        summary.skills.push(summarySkill);
                                        
                                        if(skill.assessmentGoals != null) {
                                            if(skill.assessmentGoals.length > 0) {
                                                // console.log("Skill has goals: ", skillData.name, skill.assessmentGoals);
                                                let summaryGoal =  {
                                                    activities: self.getGoalText(skill.assessmentGoals, 0),
                                                    support: self.getGoalText(skill.assessmentGoals, 1),
                                                    success: self.getGoalText(skill.assessmentGoals, 2),
                                                };
                                                
                                                summary.selectedSkills.push(skillData.name);
                                                summary.goals.push(summaryGoal);
                                            }
                                        }
                                    }
    
                                    if(typeof callback == "function")
                                        callback(summary);
                                }
                            });
                        }
                    }
                });

            });
        },

        emailTemplateHTMLString(emailTemplateLink, callback) {
            
            function minifyHTML(str) {
                return str.replace(/  +/g, ' ')
                        .replace(/\n/g, '')
                        .replace(/\r/g, '');
            }

            fetch(emailTemplateLink).then(function (response) {
                // The API call was successful!
                return response.text();
            }).then(function (html) {
                html = minifyHTML(html);
                // This is the HTML from our response as a text string
                if(typeof callback == "function")
                    callback(html);
            }).catch(function (err) {
                // There was an error
                console.error('Something went wrong loading template.', err);
            });
        },
        
        emailSendBuildMessage(callback) {
            let self = this;

            let emailTemplateLink = '/static/emailTemplate.html';

            self.emailTemplateHTMLString(emailTemplateLink, (pageHTMLString) => {  
                self.emailSummaryObject((summary) => {

                    var page = document.createElement('div');
                    page.innerHTML = pageHTMLString.trim();
        
                    page.getElementsByClassName("name")[0].innerText = summary.name;
                    page.getElementsByClassName("role")[0].innerText = summary.role;
                    page.getElementsByClassName("division")[0].innerText = summary.division;
                    page.getElementsByClassName("region")[0].innerText = summary.region;
                    page.getElementsByClassName("market")[0].innerText = summary.market;
                    page.getElementsByClassName("country")[0].innerText = summary.country;
                    page.getElementsByClassName("manager")[0].innerText = summary.manager;
                    page.getElementsByClassName("date")[0].innerText = self.currentDate(null);
        
                    let table1 = page.getElementsByClassName("table1")[0],
                        table2 = page.getElementsByClassName("table2")[0],
                        row1 = page.getElementsByClassName("row1")[0],
                        row2 = page.getElementsByClassName("row2")[0];
        
                    let row1_clone = row1.cloneNode(true),
                        row2_clone = row1.cloneNode(true);
        
                    row1.remove();
                    row2.remove();
        
                    summary.skills.forEach(function(o){
                        let r = row1_clone.cloneNode(true);
                        
                        r.getElementsByClassName("col1")[0].innerText = o.name;
                        r.getElementsByClassName("col2")[0].innerText = o.target;
                        r.getElementsByClassName("col3")[0].innerText = o.current;
                        r.getElementsByClassName("col4")[0].innerText = o.brief;
        
                        table1.append(r);
                    });
        
                    summary.goals.forEach(function(o, i){
                        let r = row2_clone.cloneNode(true);
                        let name = summary.selectedSkills[i];
        
                        r.getElementsByClassName("col1")[0].innerText = name;
                        r.getElementsByClassName("col2")[0].innerText = o.activities;
                        r.getElementsByClassName("col3")[0].innerText = o.support;
                        r.getElementsByClassName("col4")[0].innerText = o.success;
        
                        table2.append(r);
                    });
        
                    if(typeof callback == "function")
                        callback(page.innerHTML);
                })
            });
        },
        currentDate(dateToFormat) {
            var dateNow = dateToFormat == null ? new Date() : new Date(dateToFormat);
            var twoDigitMonth = ((dateNow.getMonth().length + 1) === 1) ? (dateNow.getMonth() + 1) : '' + (dateNow.getMonth() + 1);
            var currentDate = dateNow.getDate() + "/" + twoDigitMonth + "/" + dateNow.getFullYear();
            return currentDate;
        },
        emailSendBuildManagerMessage(callback) {
            let self = this;

            let emailTemplateLink = '/static/emailManagerTemplate.html';

            self.emailTemplateHTMLString(emailTemplateLink, (pageHTMLString) => {  
                self.emailSummaryObject((summary) => {

                    var page = document.createElement('div');
                    page.innerHTML = pageHTMLString.trim();

                    page.getElementsByClassName("mname")[0].innerText = summary.mname;
                    page.getElementsByClassName("name")[0].innerText = summary.name;
                    page.getElementsByClassName("role")[0].innerText = summary.role;
                    page.getElementsByClassName("division")[0].innerText = summary.division;
                    page.getElementsByClassName("region")[0].innerText = summary.region;
                    page.getElementsByClassName("market")[0].innerText = summary.market;
                    page.getElementsByClassName("country")[0].innerText = summary.country;
                    page.getElementsByClassName("date")[0].innerText = self.currentDate(null);
        
                    let table1 = page.getElementsByClassName("table1")[0],
                        table2 = page.getElementsByClassName("table2")[0],
                        row1 = page.getElementsByClassName("row1")[0],
                        row2 = page.getElementsByClassName("row2")[0];
        
                    let row1_clone = row1.cloneNode(true),
                        row2_clone = row1.cloneNode(true);
        
                    row1.remove();
                    row2.remove();
        
                    summary.skills.forEach(function(o){
                        let r = row1_clone.cloneNode(true);
                        
                        r.getElementsByClassName("col1")[0].innerText = o.name;
                        r.getElementsByClassName("col2")[0].innerText = o.target;
                        r.getElementsByClassName("col3")[0].innerText = o.current;
                        r.getElementsByClassName("col4")[0].innerText = o.brief;
        
                        table1.append(r);
                    });
        
                    summary.goals.forEach(function(o, i){
                        let r = row2_clone.cloneNode(true);
                        let name = summary.selectedSkills[i];
        
                        r.getElementsByClassName("col1")[0].innerText = name;
                        r.getElementsByClassName("col2")[0].innerText = o.activities;
                        r.getElementsByClassName("col3")[0].innerText = o.support;
                        r.getElementsByClassName("col4")[0].innerText = o.success;
        
                        table2.append(r);
                    });
        
                    if(typeof callback == "function")
                        callback(page.innerHTML);
                })
            });
        },

        emailSendUser(callback) {
            let self = this;
            let err = self.isNullEmpty(self.claims);

            if(err.s) {
                console.error(err.m);
                return;
            }

            if(self.userData.user == null) {
                console.error("sending email error, user is null");
                return;
            }

            //SEND USER EMAIL
            self.emailSendBuildMessage((messageBodyHtml) => {
                //SEND EMAIL TO OKTA Logged in
                let userid = self.assessment.userID,
                    userEmail = self.claims[3].value;

                if(self.overwriteReceiverEmail != '') {
                    console.warn('overwrite asociated staff email with..', self.overwriteReceiverEmail);
                    userEmail = self.overwriteReceiverEmail;
                }

                //TODO: ENCODING
                let encodedMessageBodyHtml = btoa(escape(messageBodyHtml));

                let messageBody = {
                    "emailDestination": "",
                    "emailCC": [],
                    "subject": "Summary",
                    "messageBodyHtml": encodedMessageBodyHtml
                };

                // live
                messageBody.emailDestination = userEmail;

                self.summaryNotify(self.accessToken, userid, messageBody).then((response) => {
                    console.log("User email sent: ");
                    console.log(response.data);

                    if(typeof callback == "function")
                        callback(response, true);
                }).catch((err) => {
                    console.log("User email sent error: ");
                    console.log(err);

                    if(typeof callback == "function")
                        callback(err, false);
                });

            });

        },
        emailSendManager(callback) {
            let self = this;
            let err = self.isNullEmpty(self.claims);

            if(err.s) {
                console.error(err.m);
                return;
            }

            if(self.userData.user == null) {
                console.error("sending email error, user is null");
                return;
            }
            
            //SEND MANAGER EMAIL
            self.emailSendBuildManagerMessage((messageBodyHtml) => {
                //SEND EMAIL TO OKTA Logged in
                let userid = self.assessment.userID;

                // live
                let managerEmail = self.getManagerEmail(self.userData.user.managersId);

                let encodedMessageBodyHtml = btoa(escape(messageBodyHtml));

                let messageBody = {
                    "emailDestination": "",
                    "emailCC": [],
                    "subject": "Summary",
                    "messageBodyHtml": encodedMessageBodyHtml
                };

                messageBody.emailDestination = managerEmail;

                self.summaryNotify(self.accessToken, userid, messageBody).then((response) => {
                    console.log("Manager email sent: ");
                    console.log(response.data);

                    if(typeof callback == "function")
                        callback(response, true);

                }).catch((err) => {
                    console.log("Manager email sent error: ");
                    console.log(err);

                    if(typeof callback == "function")
                        callback(err, false);
                });

            });
        }

        /*
            End Email METHODS
        */
    }
};