export default {
    name: 'capabilityLocalStorageAPI',
    data: function() {
        return {
            name: "BF_Module",
            module: 0,
            version: '1.00',

            isLoggedIn: false,
            isEmailSent: false,
            isPlanningCompleted: false,

            userData: {
                user: {},
                assessment: {},
                planning: {}
            },
            staticData: {
                roles: {},
                regions: {},
                managers: {},
                countrylist: {}
            },

            // testing-only
            overwriteLoginEmail: '',
            overwriteLoginName: '',
            overwriteReceiverEmail: '',
            overwriteStaffEmail: '',
            
            goalTypes: [
                //activities typeid
                'abf3bb94-de22-4a6f-a264-a65eae34510f',
                //support typeid
                '78445d19-16e1-4275-b08b-272a079fa439',
                //success typeid
                'e07e149e-cd16-4f6f-b033-065d4ebaafef'
            ]
        };
    },
    mounted: function() {
        console.log("Local API script mounted");
    },
    methods: {
        getVarSiteName() {
            let self = this;
            let l = "_";
            return self.name + l + self.module + l + self.version;
        },

        getVarVersionName() {
            let self = this;
            let l = "_";
            return self.name + l + self.version;
        },

        firstRunSetup() {
            let self = this;

            let v = self.getVarVersionName();

            localStorage.setItem(v + "_launched", "1");

            self.localSave();
        },

        setCompleted() {
            let self = this;
            let v = self.getVarVersionName();
            localStorage.setItem(v + "_isCompleted", "1");
        },

        isCompleted() {
            let self = this;
            let v = self.getVarVersionName();
            let item = localStorage.getItem(v + "_isCompleted");

            return item == "1";
        },

        localSave() {
            let self = this;
            let n = self.getVarSiteName();
            let v = self.getVarVersionName();

            localStorage.setItem(v, self.version);
            localStorage.setItem(n, JSON.stringify(self.userData));
        },
        restore (callback) {
            let self = this;
            let n = self.getVarSiteName();
            let v = self.getVarVersionName();

            var _data = localStorage.getItem(n);
            var _version = localStorage.getItem(v);

            if (_version == null) {
                self.firstRunSetup();

                if (typeof callback == "function")
                    callback(false);

                return false;
            }

            if (String(_version) != String(self.version)) {
                if (typeof callback == "function")
                    callback(false);
                return false;
            } else {
                if (_data != null && _data != "null" && _data != "") {
                    var _data_parsed = JSON.parse(_data);

                    self.userData = _data_parsed;

                    if (typeof callback == "function")
                        callback(true);

                    return true;
                } else {
                    if (typeof callback == "function")
                        callback(false);
                    return false;
                }
            }
        },
        getRestore () {
            let self = this;
            let n = self.getVarSiteName();
            let v = self.getVarVersionName();

            var _data = localStorage.getItem(n);
            var _version = localStorage.getItem(v);

            if (_version == null) {
                self.firstRunSetup();
                return null;
            }

            if (String(_version) != String(self.version)) {
                return null;
            } else {
                if (_data != null && _data != "null" && _data != "") {
                    var _data_parsed = JSON.parse(_data);
                    return _data_parsed;
                } else {
                    return null;
                }
            }
        },
        getRoleName(guid) {
            let self = this;
            let err = self.isNullEmpty(self.staticData.roles);

            if(err.s) {
                console.error(err.m);
                return;
            }

            let item = self.staticData.roles.filter((x) => x.id == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('role', guid, err2.m);
                return;
            }

            return item[0].name;
        },
        getCountryIsoName(guid) {
            let self = this;
            let err = self.isNullEmpty(self.staticData.countryList);

            if(err.s) {
                console.error(err.m);
                return;
            }

            let item = self.staticData.countryList.filter((x) => x.countryID == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('region', guid, err2.m);
                return;
            }

            return item[0].name;
        },
        getCountryIso(guid) {
            let self = this;
            let err = self.isNullEmpty(self.staticData.countryList);

            if(err.s) {
                console.error(err.m);
                return;
            }

            let item = self.staticData.countryList.filter((x) => x.countryID == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('region', guid, err2.m);
                return;
            }

            return item[0];
        },
        getRegionName(guid) {
            let self = this;
            let err = self.isNullEmpty(self.staticData.regionsAll);

            if(err.s) {
                console.error(err.m);
                return;
            }

            let item = self.staticData.regionsAll.filter((x) => x.id == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('region', guid, err2.m);
                return;
            }

            return item[0].name;
        },
        getRegion(guid) {
            let self = this;
            let err = self.isNullEmpty(self.staticData.regionsAll);

            if(err.s) {
                console.error(err.m);
                return;
            }

            let item = self.staticData.regionsAll.filter((x) => x.id == guid);

            console.log('getRegion', guid, item);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('region', guid, err2.m);
                return;
            }

            return item[0];
        },
        getManagerName(guid) {
            let self = this;

            let err = self.isNullEmpty(self.staticData.managers);

            if(err.s) {
                console.error(err.m);
                return;
            }
            
            let item = self.staticData.managers.filter((x) => x.id == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('manager', guid, err2.m);
                return;
            }

            return item[0].name + " (" + item[0].email + ")";
        },
        getManagerName2(guid) {
            let self = this;

            let err = self.isNullEmpty(self.staticData.managers);

            if(err.s) {
                console.error(err.m);
                return;
            }
            
            let item = self.staticData.managers.filter((x) => x.id == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('manager', guid, err2.m);
                return;
            }

            return item[0].name;
        },
        getDate(dateToFormat) {
            var dateNow = dateToFormat == null ? new Date() : new Date(dateToFormat);
            var twoDigitMonth = ((dateNow.getMonth().length + 1) === 1) ? (dateNow.getMonth() + 1) : '' + (dateNow.getMonth() + 1);
            var currentDate = dateNow.getDate() + "/" + twoDigitMonth + "/" + dateNow.getFullYear();
            return currentDate;
        },
        getRoleSkills(guid) {
            let self = this;
            let err = self.isNullEmpty(self.staticData.roles);

            if(err.s) {
                console.error(err.m);
                return;
            }

            let item = self.staticData.roles.filter((x) => x.id == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('role', guid, err2.m);
                return;
            }

            return item[0].skills;
        },
        getSkill(roleSkillsArr, guid) {
            let self = this;
            let err = self.isNullEmpty(self.staticData.roles);

            if(err.s) {
                console.error(err.m);
                return;
            }

            let item = roleSkillsArr.filter((x) => x.id == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('role', guid, err2.m);
                return;
            }

            return item[0];
        },
        getGoalText(goal, goalTypeIndex) {
            let self= this;

            let item = goal.filter((x) => { return x.assessmentGoalTypeID == self.goalTypes[goalTypeIndex]});
        
            return item[0].goalText;
        },
        getManagerEmail(guid) {
            let self = this;

            let err = self.isNullEmpty(self.staticData.managers);

            if(err.s) {
                console.error(err.m);
                return;
            }
            
            let item = self.staticData.managers.filter((x) => x.id == guid);

            let err2 = self.isNullEmpty(item);

            if(err2.s) {
                console.error('manager', guid, err2.m);
                return;
            }

            return item[0].email;
        },
        getUserEmailQueryString() {
            let urlParams =  new URLSearchParams(window.location.search);
            let urlEmail = urlParams.get('user_email');

            return urlEmail;
        },
        getUser(accessToken, callback) {
            let self = this,
                name = self.claims[1].value,
                email = self.claims[3].value;

            if(self.overwriteLoginEmail != '') {
                email = self.overwriteLoginEmail;
                console.warn('Overwrite login email with...', email);
            }

            if(self.overwriteLoginName != '') {
                name = self.overwriteLoginName;
                console.warn('Overwrite login name with...', name);
            }

            let user_email = self.getUserEmailQueryString();
            
            if(user_email != null && user_email != '') {
                console.log("getUser > login from manager");

                self.loginAsManager(accessToken, user_email, email).then((response) => {
                    console.log("getUser > loginAsManager > ", response);

                    if(response.data == null || response.data == '') {
                        console.log("getUser > loginAsManager > response data null - return okta user");
                        if(typeof callback == "function") {
                            callback({ 
                                name: name, 
                                email: email
                            });
                        }
                    } else {
                        console.log("getUser > loginAsManager > return user_email");
                        email = user_email;
                        if(typeof callback == "function") {
                            callback({ 
                                name: name, 
                                email: email
                            });
                        }
                    }
                })

            } else {
                console.log("getUser > basic login");

                if(typeof callback == "function") {
                    callback({ 
                        name: name, 
                        email: email
                    });
                }
            }
        },
        isNullEmpty(o) {
            if(o == null) {
                return { s: true, m: 'is null'};
            }

            if(o.length == 0) {
                return { s: true, m: 'is empty'};
            }

            return { s: false, m: null};
        }
    }
};